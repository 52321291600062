@media only screen and (min-width: 501px) {
    #table-stats {
        width: 29%;
    }

    #table-main-small {
        display: none
    }

    #fourthree-small {
        display: none
    }

    .search {
        display: inline-block;
        vertical-align: top;
        width: 200px
    }

    .subDiv {
        height: 100px;
    }

    .setting {
        display: inline-block;
        vertical-align: top;
        width: 200px;
    }

    .mainsearchcontainer {
        display: inline-block;
        vertical-align: top;
        text-align: left;
        width: 200px;
    }
}

@media only screen and (min-width: 768px) {
    .search {
        display: inline-block;
        vertical-align: top;
        width: 200px;
    }

    .subDiv {
        height: 100px;
    }

    .setting {
        display: inline-block;
        vertical-align: top;
        width: 200px;
    }

    .mainsearchcontainer {
        display: inline-block;
        vertical-align: top;
        text-align: left;
        width: 200px;
    }
}

@media only screen and (min-width: 1000px) {
    .search {
        display: inline-block;
        vertical-align: top;
        width: 240px;
    }

    .subDiv {
        height: 100px;
    }

    .setting {
        display: inline-block;
        vertical-align: top;
        width: 240px;
    }

    .mainsearchcontainer {
        display: inline-block;
        vertical-align: top;
        text-align: left;
        width: 240px;
    }
}

@media only screen and (min-width: 1100px) {
    .search {
        display: inline-block;
        vertical-align: top;
        width: 270px;
    }

    .subDiv {
        height: 100px;
    }

    .setting {
        display: inline-block;
        vertical-align: top;
        width: 270px;
    }

    .mainsearchcontainer {
        display: inline-block;
        vertical-align: top;
        text-align: left;
        width: 270px;
    }
}

@media only screen and (min-width: 1304px) {
    .search {
        display: inline-block;
        vertical-align: top;
        width: 340px;
    }

    .subDiv {
        height: 100px
    }

    .setting {
        display: inline-block;
        vertical-align: top;
        width: 340px;
    }

    .mainsearchcontainer {
        display: inline-block;
        vertical-align: top;
        width: 340px;
        text-align: left;
    }
}

.subDiv {
    display: flex;
    justify-content: space-around;
}

#table-stats {
    line-height: 0px;
    text-align: center;
}

#table-main {
    text-align: center;
}

#table-main-small {
    text-align: center;
}

#mobile-style {
    font-size: 13px;
    padding: 0px 5px 0 5px;
    margin: 0px;
    text-align: left;
    vertical-align: middle;
    line-height: 15px;
    border: none
}

@media only screen and (max-width: 500px) {
    #table-stats {
        width: 100%;
    }

    #fourthree {
        display: none
    }

    #table-main {
        display: none
    }

    table#fourfour {
        display: none
    }

    table#fourfive {
        display: none
    }

    .search {
        display: block;
        margin-bottom: 10px;
        border: 1px solid lightgrey;
        padding: 10px;
        height: 110px;
        border-radius: 5px;
    }

    .subDiv {
        display: block;
    }

    .setting {
        display: none;
    }

    .mainsearchcontainer {
        text-align: left;
        display: block;
        margin-bottom: 15px;
    }
}

table#fourfour th,
table#fourfour td {
    /* border: 1px solid black; */
    padding: 5px;
    text-align: center;
    vertical-align: middle;
    text-align: center;
}

table#fourfour th {
    background-color: #f2f2f2;
    color: black;
    font-weight: bold;
    width: 25px;
    text-align: center;
}

.table-wrapper {
    overflow-x: auto;
    max-height: 400px; /* Set the maximum height for scrolling */
  }
  
  .table-wrapper table {
    border-collapse: collapse;
    width: 100%;
  }
  
  .table-wrapper thead th {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 1;
  }
  
  .table-wrapper tbody {
    display: block;
    overflow-y: auto;
    max-height: calc(100% - 30px); /* Adjust this value based on your dialog height and header height */
  }
  
  .table-wrapper tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  